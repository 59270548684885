import React from "react";
import { Flex } from 'rebass/styled-components'
require("./plan_categories/style.css");

const IconCategorie = ({ ...props }) => {
  props.icon = props.icon.replace('icon-', 'icon-categories-');
  if (props.size) {
    props.style = props.style || {};
    props.style.fontSize = /^\d+$/.test(props.size) ? props.size + 'px' : props.size;
    props.style.width = /^\d+$/.test(props.size) ? props.size + 'px' : props.size;
  }
  return <Flex alignItems="center" justifyContent="center">
    {props.icon && (
      <span className={props.icon} style={props.style}></span>
    )}
  </Flex>;
};

export default IconCategorie;
